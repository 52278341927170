.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.trusted-by-wrapper {
  display: flex;
  flex-direction: row;
  width: 100vw;
  overflow-x: auto;
  overflow-y: hidden;
}

.trusted-card-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  width: 200px;
  height: 140px;
  cursor: grabbing;
  margin: 20px;
}

